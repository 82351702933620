// @ts-strict-ignore
import React, { useEffect, useState } from "react"

import { Loading, Stack } from "@kiwicom/orbit-components"

import { EmberApiError } from "api/errors"
import { fetchDriverDetails } from "api/person"

import Container from "components/container"
import { EmberCardSection } from "components/generic/ember-card"
import { EmptyStateCardSection } from "components/generic/empty-state-card-section"
import { DriveLayout } from "components/layout-custom"
import LeaveAccountsSummaryCard from "components/people/leave/leave-accounts-summary-card"
import { TimeOffBookingSection } from "components/people/leave/time-off-booking-section"

import { DriverLeaveAccounts } from "types/person"

import { useGlobalState } from "utils/state-utils"

const Page = () => {
  const { loggedInPersonUid } = useGlobalState()
  const [driverDetails, setDriverDetails] = useState<DriverLeaveAccounts>(null)
  const [refreshCounter, setRefreshCounter] = useState(0)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<EmberApiError>(null)
  console.log(error)

  useEffect(() => {
    if (loggedInPersonUid != null) {
      setError(null)
      setLoading(true)
      setDriverDetails(null)
      const sub = fetchDriverDetails({
        driverUid: loggedInPersonUid,
        onSuccess: setDriverDetails,
        onError: setError,
        onCompletion: () => setLoading(false),
        queryParams: { contracts: true, leave_accounts: true },
      })
      return () => sub.unsubscribe()
    }
  }, [loggedInPersonUid, refreshCounter])

  return (
    <DriveLayout title="Leave" padded={true}>
      <Container>
        <Stack spacing="large">
          <Container>
            {driverDetails?.leaveAccounts?.length > 0 && (
              <LeaveAccountsSummaryCard
                leaveAccounts={driverDetails.leaveAccounts}
                contracts={driverDetails.contracts}
                audience="driver"
                refreshOnEdit={() => setRefreshCounter(refreshCounter + 1)}
              />
            )}
            {loading && (
              <EmberCardSection>
                <Loading type="boxLoader" text="Loading accounts..." />
              </EmberCardSection>
            )}
            {driverDetails?.leaveAccounts?.length == 0 && !loading && (
              <EmptyStateCardSection title="No leave allowance found" />
            )}
          </Container>
          <Container>
            <TimeOffBookingSection
              title="Time Off Booking"
              audience="driver"
              showAddButton
              refreshCounter={refreshCounter}
              refreshFunc={() => setRefreshCounter(refreshCounter + 1)}
            />
          </Container>
        </Stack>
      </Container>
    </DriveLayout>
  )
}

export default Page
